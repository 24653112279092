import React from 'react';
import { Popup } from 'semantic-ui-react'

export default (element, content, spanPros = {}, props = {}) => (
  <Popup
    size="mini"
    content={ content }
    position="bottom center"
    trigger={ <span {...spanPros}>{element}</span> }
    {...props}
  />
);
